var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-row',{staticClass:"content-fix org"},[_c('a-row',{staticClass:"content-title",attrs:{"type":"flex"}},[_c('a-button',{staticClass:"org back-btn",staticStyle:{"margin-right":"15px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
          name: 'activity'
        })}}},[_c('i',{staticClass:"t-transition icons icon-chevron-left"}),_vm._v(" Назад ")]),_c('a-button',{staticStyle:{"margin-left":"auto"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'doc-create' })}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(_vm._s(_vm.$t("Add"))+" ")],1)],1),_c('a-row',{staticClass:"table__filtrs",attrs:{"type":"flex"}},[_c('h2',[_vm._v(_vm._s(_vm.title[_vm.$i18n.locale]))]),_c('a-button',{staticStyle:{"margin":"0 0 15px auto"},attrs:{"disabled":_vm.cardsIsEmpty,"type":"primary"},on:{"click":function($event){return _vm.removeItem(_vm.selectedPosts)}}},[_c('a-icon',{staticClass:"action-btns",attrs:{"type":"delete"}}),_vm._v(" Удалить ")],1)],1),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.list.results,"loading":_vm.loading,"pagination":false,"scroll":{ x: 1800, y: 600 }},scopedSlots:_vm._u([{key:"id",fn:function(item){return [_c('a-checkbox',{attrs:{"checked":_vm.selectedPosts.includes(+item.id),"value":+item.id},on:{"change":function($event){return _vm.toggleItemId(item.id)}}}),_c('span',{staticClass:"td-post-item",on:{"click":function($event){return _vm.$router.push({ name: 'doc-update', params: { id: item.id } })}}},[_c('b',[_vm._v(_vm._s(item.id))])])]}},{key:"theme",fn:function(item){return [_c('span',{staticClass:"text-center td-post-item",staticStyle:{"cursor":"pointer","display":"block","width":"100%","text-align":"left"},on:{"click":function($event){return _vm.$router.push({ name: 'doc-update', params: { id: item.id } })}}},[_vm._v(_vm._s(item.year || "Не заполнено")+" ")])]}},{key:"npa",fn:function(item){return [_c('a-button',{staticStyle:{"margin-right":"10px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
            name: 'docTheme',
            params: {
              act_id: _vm.$route.params.act_id,
              year: item.id
            }
          })}}},[_vm._v(" "+_vm._s(_vm.$t("Add"))+" ")])]}},{key:"status",fn:function(item){return [_c('a-tag',{attrs:{"color":item.is_active ? 'geekblue' : 'volcano'}},[_vm._v(" "+_vm._s(item.is_active ? _vm.$t("Published") : _vm.$t("NotPublished"))+" ")])]}},{key:"author",fn:function(item){return [_vm._v(" "+_vm._s(item.author || "-")+" ")]}},{key:"created_at",fn:function(item){return [_vm._v(" "+_vm._s(_vm.moment(item.created_at).format("LLL"))+" ")]}},{key:"updated_at",fn:function(item){return [_vm._v(" "+_vm._s(_vm.moment(item.updated_at).format("LLL"))+" ")]}},{key:"operation",fn:function(item){return [_c('a-button',{staticStyle:{"margin-right":"10px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'doc-update', params: { id: item.id } })}}},[_c('a-icon',{staticClass:"action-btns",attrs:{"type":"edit"}})],1),_c('a-popconfirm',{attrs:{"cancel-text":"Нет","ok-text":"Да","title":"Вы действительно хотите удалить?"},on:{"confirm":function($event){return _vm.removeItem(item.id)}}},[_c('a-button',{staticStyle:{"margin-right":"10px"},attrs:{"type":"danger"}},[_c('a-icon',{staticClass:"action-btns",attrs:{"type":"delete"}})],1)],1)]}}])}),_c('a-row',{staticClass:"mt-1",staticStyle:{"padding-bottom":"15px"}},[_c('a-pagination',{key:_vm.perPage,attrs:{"current":_vm.page,"default-page-size":_vm.perPage,"total":+_vm.list.count || 0},on:{"change":_vm.toPage}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }